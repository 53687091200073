// import store from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { _lang } from '@/utils/lang'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "ROOT",
  //   redirect: "/home",
  // },
  {
    path: "/home",
    name: "HOME",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/home/index.vue")
  },
  {
    path: "/issue",
    name: "ISSUE",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/issue/index.vue"),
    meta: {
      title: _lang('FEEDBACK'),
    },
  },
  {
    path: "/welfare",
    name: "WELFARE",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/welfare/index.vue"),
    meta: {
      title: 'WELFARE',
    },
  },
  {
    path: "/share",
    name: "SHARE",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/share/index.vue"),
    meta: {
      title: 'SHARE',
    },
  },
  {
    path: "/page",
    name: "PAGE",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/page/index.vue"),
    meta: {
      title: 'PAGE',
    },
  },
  {
    path: "/secret",
    name: "SECRET",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/secret/index.vue"),
    meta: {
      title: _lang('privacyPolicy'),
    },
  },
  {
    path: "/readme",
    name: "README",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/readme/index.vue"),
    meta: {
      title: _lang('termsServices'),
    },
  },
  {
    path: "/",
    name: "sereal",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/website/index.vue"),
    meta: {
      title: 'Sereal',
    },
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/PrivacyPolicy/index.vue"),
    meta: {
      title: 'Privacy Policy',
    },
  },
  {
    path: "/TermsofService",
    name: "TermsofService",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/TermsofService/index.vue"),
    meta: {
      title: 'Terms of Service',
    },
  },
  {
    path: "/evaluate",
    name: "EVALUATE",
    component: () =>
      import(/* webpackChunkName: "Charpter" */ "../views/evaluate/index.vue"),
    meta: {
      title: _lang('Rating'),
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
      //判断是否有标题
      document.title = to.meta.title as string
  } else {
      document.title = ''
  }
  next()
})

export default router;
